import '../../fonts/austinFont.css'
import '../../fonts/fedraFont.css'
import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontSize: 'md',
      },
    },
  },
  colors: {
    gold: '#b7870c',
    hoverGold: '#b7870c33',
    blue: '#030040',
    hoverBlue: '#030040b3',
    white: '#ffffff',
    red: '#ff0000',
    error: 'rgb(229, 62, 62)',
    gray: {
      50: '#96969F',
    },
    tan: {
      50: '#EFE7D6',
      heading: '#b39c60',
    },
  },
  fonts: {
    body: 'Fedra Sans',
    heading: 'Austin Web',
    austin: 'Austin Web',
    fedraSans: 'Fedra Sans',
  },
  textStyles: {
    heading: {
      fontSize: '2xl',
      fontFamily: 'austin',
      fontWeight: '400',
      lineHeight: 1.1,
    },
  },
  components: {
    Button: {
      baseStyle: {
        textTransform: 'uppercase',
        lineHeight: 1,
      },
      variants: {
        solid: {
          bg: 'blue',
          color: 'white',
          fontWeight: 'normal',
          _hover: { bg: 'hoverBlue' },
          _active: { bg: 'hoverBlue' },
        },
      },
      defaultProps: {
        variant: 'solid',
      },
    },
    Link: {
      variants: {
        text: {
          color: 'gold',
          textDecoration: 'underline',
        },
      },
    },

    Container: {
      baseStyle: {
        px: 8,
      },
    },
  },
})

// Defined separately from rest of theme to prevent default values from conflicting with customizations
theme.components.Heading = {
  baseStyle: {
    fontFamily: 'austin',
    fontWeight: '400',
    lineHeight: 1.1,
    fontSize: ['3xl', null, '4xl'],
  },
  variants: {
    h1: {
      fontFamily: 'austin',
      fontWeight: '400',
      // Workaround for Chakra bug with fontSize prop getting merged instead of overridden
      fontSize: ['36px', '56px', '56px'],
      textTransform: 'uppercase',
      lineHeight: 1,
    },
  },
}

export default theme
